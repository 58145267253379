// React 框架全局脚本

// 全局导入项
// React基本
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; // 基组件
// bootstrap 用户界面库
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
// 粉糖粒子样式
import './static/css/default.css';
// 异步通信 axios
import axios from 'axios';

axios.defaults.withCredentials = true; // 请求允许携带cookie

// 创建根
const root = ReactDOM.createRoot(document.getElementById('root'));
// 开始渲染
root.render(
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>

    <App />
);