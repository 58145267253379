import HomePage from "./views/HomePage";

function App(){
    return(
        <>
            <HomePage></HomePage>
        </>
    )
}

export default App;